'use client';
import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useUser from '@haaretz/s-atoms/user';
import { NavigationItems, ItemList } from '@haaretz/s-nav-items-group';
import { useIntersectionObserverWithElementNonStop } from '@haaretz/s-use-intersection-observer/observedElement';
import * as React from 'react';
import s9 from 'style9';

// TODO: Convert this to a dynamic import after the Next.js bug is fixed
//       (https://github.com/vercel/next.js/issues/63918)
import getUserSubscriptionDetails from '../../utils/getUserSubscriptionDetails';
import MobileNavigation from '../NavigationMenu/MobileNavigation';
// TODO: Convert this to a dynamic import after the Next.js bug is fixed
//       (https://github.com/vercel/next.js/issues/63918)
import MobileUserMenu from '../UserMenu/MobileUserMenu';

import BottomNavigationItem from './BottomNavigationItem';

import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';

// `c` is short for `classNames`.
const c = s9.create({
  base: {
    position: 'fixed',
    height: `calc(${space(13)}) + env(safe-area-inset-bottom, 0)`,
    zIndex: zIndex('masthead'),
    bottom: 0,
    width: '100%',
    backgroundColor: color('neutral200'),
    color: color('neutral900'),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto 1fr 1fr',
    justifyItems: 'center',
    alignItems: 'center',
    cursor: 'none',
    ...border({
      color: color('primary400'),
      side: 'all',
      spacing: 1,
      style: 'solid',
      width: '1px',
    }),
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'none',
        },
      })
    ),
  },
  titleAndIconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    paddingTop: space(1.5),
  },
  title: {
    color: color('neutral1000'),
    ...typesetter(-4),
  },
  icon: {
    fontSize: space(7),
  },

  menuBtnWrapper: {
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
  },
  menuIcon: {
    height: '100%',
    width: '100%',
  },
  hamburger: {
    '--mHTxtClr': color('neutral1000'),
  },
  obeservedElement: {
    height: '150dvh',
  },
  animation: {
    transitionProperty: 'opacity',
    transitionDuration: '0.25s',
    transitionTimingFunction: 'ease-in-out',
  },
  fadeInAnimation: {
    opacity: 1,
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationDelay: 'var(--animationDelay)',
    animationName: s9.keyframes({
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    }),
  },
});

export interface BottomMobileNavbarProps {
  /** The Children to be rendered inside `<BottomMobileNavbar>` */
  children?: React.ReactNode;
  mainNavigationSubEntries: NavigationEntryFragment['subEntries'];
  navUserMenuItems: NavigationEntryFragment['subEntries'];
}

export default function BottomMobileNavbar({
  mainNavigationSubEntries,
  navUserMenuItems,
}: BottomMobileNavbarProps) {
  const user = useUser('cookieValue');

  const [isShowAlefIcon, setIsShowAlefIcon] = React.useState(false);
  const [enableAnimation, setEnableAnimation] = React.useState<boolean>(false);

  const { showPurchaseBtn, userProductType } = getUserSubscriptionDetails(user);

  // note : this is for changing the menuBtn icon on scrolling
  const [showHamburger, obeservedElement] = useIntersectionObserverWithElementNonStop({
    styleExtend: [c.obeservedElement],
  });

  const [currentSelection, setCurrentSelection] = React.useState<string | null>(null);
  const [isUsingKeyboard, setIsUsingKeyboard] = React.useState<boolean>(false);

  const onClick = ({ hasMenu, name }: { hasMenu: boolean; name: string | null }) => {
    if (currentSelection === 'mobileNavigation' && name === 'settings') {
      setCurrentSelection(name);
    } else if (currentSelection === 'mobileNavigation' && hasMenu) {
      setCurrentSelection(null);
    } else {
      setCurrentSelection(name);
    }
  };

  React.useEffect(() => {
    const isShowAlef = !(currentSelection === 'mobileNavigation' || showHamburger);
    setEnableAnimation(true);
    setIsShowAlefIcon(isShowAlef);
  }, [currentSelection, showHamburger]);

  const mainNavigation = mainNavigationSubEntries?.filter(entry => {
    if (entry.kind === 'purchase') {
      return entry.includeUserTypes?.includes(userProductType) && showPurchaseBtn;
    }
    return entry.title && entry.href;
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      setIsUsingKeyboard(true);
    }
  };

  const isOnMainNavigation = currentSelection === 'mobileNavigation';

  return (
    <>
      <MobileNavigation
        mainNavigation={mainNavigation}
        isOpen={currentSelection === 'mobileNavigation'}
        onClick={() => onClick({ name: null, hasMenu: true })}
        focusOnFirstItem={isUsingKeyboard}
      />
      <MobileUserMenu
        navUserMenuItems={navUserMenuItems}
        isOpen={currentSelection === 'settings'}
        onClick={() => onClick({ name: null, hasMenu: true })}
        focusOnFirstItem={isUsingKeyboard}
      />
      <NavigationItems>
        <ItemList styleExtend={[c.base]} removeStyle={isOnMainNavigation || !currentSelection}>
          {[...Array(5)].map((_, i) => (
            <BottomNavigationItem
              key={String(i)}
              currentSelection={currentSelection}
              onClick={onClick}
              enableAnimation={enableAnimation}
              isShowAlefIcon={isShowAlefIcon}
              itemNumber={i}
              onKeyDownCapture={handleKeyDown}
            />
          ))}
        </ItemList>
      </NavigationItems>
      {obeservedElement}
    </>
  );
}
