'use client';
import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import s9 from 'style9';

import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';
import type { StyleExtend } from '@haaretz/s-types';

interface Props {
  sectionNavigationItems: NavigationEntryFragment['subEntries'];
  styleExtend?: StyleExtend;
}

export default function SectionNavigation({ sectionNavigationItems, styleExtend = [] }: Props) {
  const c = s9.create({
    ul: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color('contentBg'),
      paddingBottom: space(3),
      paddingTop: space(3),
      borderTopWidth: space(1),
      borderTopStyle: 'solid',
      borderTopColor: color('pageBg'),
      ...typesetter(-2),
    },
    separator: {
      '::after': {
        content: '" | "',
      },
    },
    htzLink: {
      fontWeight: 700,
      paddingInlineStart: space(1),
    },
  });
  const biAction = useBi();
  return (
    <ul className={s9(c.ul, ...styleExtend)}>
      {sectionNavigationItems.map((item, idx) => {
        if (!item || !item.title || !item.href) return null;
        const isLast = idx === sectionNavigationItems.length - 1;
        return (
          <li key={idx} className={s9(!isLast && c.separator)}>
            <HtzLink
              className={s9(c.htzLink)}
              href={item?.href}
              onClick={() => {
                biAction({
                  feature: 'Top navigation',
                  feature_type: 'Content',
                  action_id: 133,
                  campaign_details: item.title,
                });
              }}
            >
              {item.title}
            </HtzLink>
          </li>
        );
      })}
    </ul>
  );
}
