'use client';
import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useUser from '@haaretz/s-atoms/user';
import Icon from '@haaretz/s-icon';
import { Item } from '@haaretz/s-nav-items-group';
import * as React from 'react';
import s9 from 'style9';

import getNavbarItem from '../../utils/getNavbarItem';
import getUserSubscriptionDetails from '../../utils/getUserSubscriptionDetails';
import Hamburger from '../Hamburger';

// `c` is short for `classNames`
const c = s9.create({
  titleAndIconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    ':active': {
      transform: 'scale(0.8)',
    },
  },
  title: {
    color: color('neutral1000'),
    ...typesetter(-4),
  },
  icon: {
    fontSize: space(7),
  },
  menuBtnWrapper: {
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
  },
  alefWrapper: {
    '--wrapper-size': space(15),
    position: 'relative',
    backgroundColor: color('primary1000'),
    top: space(-4),
    height: 'var(--wrapper-size)',
    width: 'var(--wrapper-size)',
    borderRadius: radius('circle'),
    ...border({
      color: color('neutral200'),
      side: 'all',
      spacing: 1,
      style: 'solid',
      width: '2px',
    }),
    zIndex: zIndex('masthead'),
    ':active': {
      transform: 'scale(0.8)',
    },
  },
  menuIcon: {
    height: space(16),
    width: space(16),
    marginInlineStart: space(-2),
    marginTop: space(-1.5),
  },
  hamburger: {
    '--mHTxtClr': color('neutral1000'),
  },
  fadeInAnimation: {
    opacity: 1,
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationName: s9.keyframes({
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    }),
  },
  bold: {
    fontWeight: 700,
  },
});

export interface BottomNavigationItemProps {
  currentSelection: string | null;
  onClick: (params: { hasMenu: boolean; name: string | null }) => void;
  enableAnimation: boolean;
  isShowAlefIcon: boolean;
  itemNumber: number;
  onKeyDownCapture: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function BottomNavigationItem({
  itemNumber,
  currentSelection,
  enableAnimation,
  isShowAlefIcon,
  onClick,
  onKeyDownCapture,
}: BottomNavigationItemProps) {
  const renderingKind = useRenderingKind();
  const isBlock = renderingKind === 'blocked';

  const user = useUser('cookieValue');
  const { isPartialSubscriber } = getUserSubscriptionDetails(user);

  const [navbarItem, setNavBarItem] = React.useState(
    getNavbarItem(isBlock ? 'anonymous' : 'paying', isPartialSubscriber, itemNumber)
  );
  React.useEffect(() => {
    const newItem = getNavbarItem(user?.userType, isPartialSubscriber, itemNumber);

    setNavBarItem(newItem);
  }, [user?.userType, isPartialSubscriber, itemNumber]);

  const { name, title, hasMenu, iconName, href } = navbarItem || {};

  const isMainMenuBtn = name === 'mobileNavigation';
  const isPurchase = name === 'purchase';
  if (isMainMenuBtn) {
    return (
      <Item
        styleExtend={[c.alefWrapper]}
        key={name}
        hasMenu={hasMenu}
        onClick={() => {
          onClick({ name, hasMenu });
        }}
        kind="mainMenu"
        onKeyDownCapture={onKeyDownCapture}
      >
        <div className={s9(c.menuBtnWrapper, c.hamburger)}>
          {isShowAlefIcon ? (
            <Icon
              icon={iconName}
              variant="brand"
              inlineStyle={{ '--icon--secondary-color': color('neutral100') }}
              styleExtend={[c.menuIcon]}
            />
          ) : (
            <Hamburger
              isOpen={currentSelection === 'mobileNavigation'}
              enableAnimation={enableAnimation}
              inlineStyle={{
                '--mHTxtClr': color('neutral200'),
                '--mHamHWidth': space(6),
                '--mHamHeight': space(1),
                '--mHSpacing': space(2),
              }}
            />
          )}
        </div>
      </Item>
    );
  }
  return (
    <Item
      key={name}
      styleExtend={[c.titleAndIconWrapper, c.fadeInAnimation]}
      href={href}
      hasMenu={hasMenu}
      currentSelection={currentSelection}
      onClick={() => {
        onClick({ name, hasMenu });
      }}
      onKeyDownCapture={onKeyDownCapture}
    >
      {iconName && (
        <Icon
          icon={iconName}
          variant="neutral"
          inlineStyle={
            isPurchase
              ? { '--_icn-dflt-c': color('salesBtn') }
              : { '--_icn-dflt-c': color('neutral900') }
          }
          styleExtend={[c.icon]}
        />
      )}
      {title && <span className={s9(c.title, isPurchase && c.bold)}>{title}</span>}
    </Item>
  );
}
