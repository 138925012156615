'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Checkbox from '@haaretz/s-checkbox';
import setAndPersistColorMode, { useColorMode } from '@haaretz/s-color-mode';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend } from '@haaretz/s-types';

export interface ColorModeProps {
  styleExtend?: StyleExtend;
}

// `c` is short for `classNames`
const c = s9.create({
  colorModeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: space(2),
    borderRadius: radius('large'),
    padding: space(2),
    marginInlineStart: `-${space(2)}`,
    backgroundColor: color('neutral100', { opacity: 0.5 }),
    marginTop: space(2),
    ...merge({
      ...mq({
        from: 'l',
        value: {
          marginTop: space(10),
        },
      }),
    }),
  },
  colorModeCbWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  colorModeLabel: {
    ...typesetter(1),
    ...merge({
      ...mq({
        from: 's',
        value: {
          ...typesetter(-1),
        },
      }),
      ...mq({
        from: 'xxl',
        value: { ...typesetter(-2) },
      }),
    }),
  },
  mobileDmTxt: {
    fontWeight: 700,
  },
});

export default function ColorMode({ styleExtend = [] }: ColorModeProps) {
  const isAfterUserChange = React.useRef(false);
  const { colorMode: currentColorMode } = useColorMode() || { colorMode: 'light' };
  const [colorMode, setColorMode] = React.useState(currentColorMode);
  const isDarkMode = colorMode === 'dark';
  const isSystemDefault = colorMode === 'system';
  const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const biAction = useBi();

  React.useEffect(() => {
    let newColor = colorMode || 'light';
    if (isDarkMode) newColor = 'dark';
    if (isSystemDefault) newColor = 'system';
    setAndPersistColorMode(newColor, isAfterUserChange.current);
  }, [colorMode, isDarkMode, isSystemDefault]);

  return (
    <div className={s9(c.colorModeWrapper, ...styleExtend)}>
      <Checkbox
        appearance="toggle"
        styleExtend={[c.colorModeCbWrapper, c.mobileDmTxt]}
        labelStyleExtend={[c.colorModeLabel]}
        checked={isDarkMode || (isSystemDefault && isSystemDark)}
        state={isSystemDefault ? 'disabled' : 'enabled'}
        onChange={() => {
          biAction({
            campaign_name: 'darkmode',
            feature: 'Account Ruler on Header',
            feature_type: 'Personal',
            campaign_details:
              isDarkMode || (isSystemDefault && isSystemDark) ? 'darkmode off' : 'darkmode on',
            action_id: 169,
          });

          isAfterUserChange.current = true;
          return setColorMode(isDarkMode ? 'light' : 'dark');
        }}
      >
        {fork({ default: 'מצב לילה (beta)', hdc: 'Dark Mode (beta)' })}
      </Checkbox>
      <Checkbox
        appearance="checkbox"
        styleExtend={[c.colorModeCbWrapper]}
        labelStyleExtend={[c.colorModeLabel]}
        checked={isSystemDefault}
        onChange={() => {
          isAfterUserChange.current = true;
          if (isSystemDefault) {
            const systemDefault = isSystemDark ? 'dark' : 'light';
            return setColorMode(systemDefault);
          }
          return setColorMode('system');
        }}
      >
        {fork({ default: 'על פי הגדרות מערכת ההפעלה', hdc: 'system default' })}
      </Checkbox>
    </div>
  );
}
